.tooltip{
  min-width: 318px;
  padding: 16px 22px;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  background-color: #FFF;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.20);
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  background-color: #FFF;
}

.tooltipTitle{
  color: #404040;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.tooltipText{
  color: #404040;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.loanAmount{
  display: flex;
  flex-direction: column;
  gap:6px;
}

.summatory{
  display: flex;
  flex-direction: column;
  text-align: right;
  gap:2px;
  width: 114px;

  div {
    width: 100%;
    
  }

  >:last-child{
    border-top: 2px solid #404040;
    font-weight: 700;
  }
}