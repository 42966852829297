.placeLabel {
  position: relative;
  transition: 0.2s ease all;

  :global {
    input,
    .ant-select-show-search .ant-select-selector,
    textarea {
      padding-bottom: 0;
    }
    textarea {
      padding-top: 1.5rem;
    }

    .ant-select-show-search input {
      position: absolute;
      bottom: 0;
    }
  }
}

.focused {
  background-color: white;

  input {
    -webkit-background-clip: text !important;
  }
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: .5rem;
  top: 50%;
  transition: 0.2s ease all;
  transform: translateY(-50%);
  white-space: nowrap;
  z-index: 100;
  color: #585858;
  text-overflow: ellipsis;
  width: 89%;
  overflow: hidden;
}

.labelFloat {
  font-size: .562rem !important; // freaking ant
  top: 0.7rem;
  text-transform: uppercase;
}
