.cardContainer{
    display: flex;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex: 1 0 0;
    flex-wrap: wrap;
  
    :global{
      & .card{
        display: flex;
        height: 120px;
        min-width: 266px;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.20);
      }
  
      & .header{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        align-self: stretch;
  
        & .icon{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
        }
  
        & .title{
          color:#404040;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 100% */
        }
  
      }
  
      & .text{
        color: #404040;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 100% */
      }
  
    }
  
  }