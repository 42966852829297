@import "src/scss/_vars.scss";

.phoneInputField {
  width: 100%;
  display: flex;
  height: 60px;
  padding: 8px 24px 8px 8px;
  justify-content: left;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px !important;
  border: 1px solid #E0E0E0;
  background: #FFF !important;
  color: #404040;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  
  &::placeholder{
        color: #BFBFBF;
  }

}

.labelContainer{
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 5px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.labelText{
    color: #404040;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
