.resultsContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  >:last-child{
    border-top: 1px solid #404040;
    padding-top: 24px;
  }
}

.editLink{
  margin-top: 12px;
  width: 100%;
  color: #2566B0;
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; 
  letter-spacing: 0.14px;
  cursor: pointer;
}

.resultItem {
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
  gap: 10px;
}

.resultName {
  display: flex;
  align-items: center;
  color: #404040;
  text-align: left;
  white-space: nowrap;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
  gap: 4px;
}

.resultValue {
  display: flex;
  align-items: center;
  color: #404040;
  text-align: right;
  white-space: nowrap;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.dashedLine {
  width: 100%;
  border: 1px dashed #E0E0E0;
}