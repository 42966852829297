body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 16px;
  color: #333 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.anticon-check-circle svg, .anticon-close-circle svg {
  width: 20px;
  height: 20px;
  margin-top: 1px;
  margin-right: .25rem;
}

.ant-select-clear {
  right: 0.5rem !important;
  top: 1.466rem !important;
  height: 21px;
  width: 20px;
  border-radius: 50%;
  z-index: 100;
  & > .anticon-close-circle svg {
    z-index: 2;
  }
}

.ant-input {
  background-color: #F5FAFF !important;
  box-sizing: border-box;
  height: 3.25rem !important;
  padding-left: 8px !important;
  padding-top: 7px;
}

.ant-select-selector {
  background-color: #F5FAFF !important;
  box-sizing: border-box;
  height: 52px !important;
  border-radius: 4px;
  padding-left: 8px !important;
  &.ant-select-arrow {
    right: 11px !important;
    padding-right: .733rem !important;
    background-color: #2f54eb !important;
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 3.7rem;
}

.ant-radio-group {
  display: flex;
  flex-flow: row nowrap;
  & > .ant-radio-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px !important;
    flex: 1 1 0;
    width: 0;
  }
}

.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}

.ant-input-number-input {
  box-sizing: border-box;
  height: 3.25rem;
  padding-top: .5rem;
  background-color: #F5FAFF !important;
  padding-left: 7px;
}

.ant-form-item-has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-arrow {
  right: 2.56rem;
}

.ant-form-item {
  margin-bottom: 12px;
}

.ant-form-item-children-icon {
  margin-left: 1rem;
}

.assetsSubtitle {
  width: 100%;
  text-align: center;
  margin: 0 0 -0.26rem;
  padding-top: 0.4rem;
  padding-bottom: 1.33rem;
}

.marginBottomSubZero {
  margin-bottom: -6px !important;
}

.editAssetsIconLabel {
  text-decoration: underline;
}

.editAssetsIcon {
  margin-right: .5rem;
  margin-bottom: -4px;
}

.mobileSpacing {
  display: none;
}

.mobileSpacingExtra {
  display: none;
}

.alternateAddressTitle {
  padding-top: 2rem !important;
}

@media screen and (max-width: 640px) {
  .ant-row {
    margin-bottom: 6px !important;
  }

  .mobileSpacing {
    &:after {
      content: '.';
      visibility: hidden;
    }
    display: block;
    max-height: 6px;
  }

  .mobileSpacingExtra {
    &:after {
      content: '.';
      visibility: hidden;
    }
    display: block;
    min-height: 26px;
  }

  .alternateAddressTitle {
    padding-top: 1.625rem !important;
    margin: 0 !important;
  }

  .narrowButtons {
    width: 75%;
  }

  .assetsSubtitle {
    text-align: left;
    padding-top: 0;
    padding-bottom: 1rem;
  }
}
