.loanFinanceModalTitleContainer {
    display: flex;
}

.loanFinanceModalIcon {
    color: #faad14;
    margin-right: 16px;
}

.loanFinanceModalTitleText {
    margin: 0;
}

.loanFinanceModalNextCTA {
    margin-left: 12px;
}

.loanFinanceModalContentWrapper {
    margin-bottom: 0;
    padding-left: 24px;

    li:not(:last-child) {
        margin-bottom: 8px;
    }
}

