.wide {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 12px !important;
}

.purposeButton {
  width: 50%;
  min-width: 250px;
  background: var(--default-button-background-color) !important;
  border-color: var(--default-button-border-color) !important;
  border: 1px solid;
  border-radius: 4px !important;
  height: 52px !important;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  &:hover {
    background-color: var(--default-button-hover-background-color) !important;
    & span {
      color:  var(--default-button-hover-text-color) !important;
      font-weight: 700;
      font-size: 1rem;
    }
  }

  & span {
    font-size: 1rem;
    color: #384A68;
  }
}

.disclaimer {
  padding: 0.5rem;
  & > span {
    margin: auto;
    text-align: center;
  }
}