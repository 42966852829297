.link {
  text-decoration: underline;
}

.successStepMarineSubHeader {
  font-weight: bold;
  margin-bottom: 0px;
}

.successStepNextList {
  margin: 0px;
  padding-left: 16px;

  li {
    padding-left: 12px;
    padding-top: 8px;
  }
}

.successStepMarineHeader {
  color: var(--Neutral-neutral-90, #404040);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 32px;
  text-align: center;
}

.successStepMarineBodyContainer {
  width: 100%;
}

.successStepMarineFooterText {
  margin-top: 24px;
  font-weight: bold;
}
