.body {
  margin-top: 1.5rem;
}

.form {
  margin: 2rem 0;
}

.phones {
  display: flex;
  flex-direction: column;
  > b {
    margin-bottom: 0.5rem;
  }
}