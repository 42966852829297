@import "src/scss/_vars.scss";

.pageFooterContainer {
  border: solid 1px var(--step-footer-border-color) !important;
  border-radius: 24px !important;
  padding: 12px 12px !important;
  background: var(--step-footer-background-color) !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.pageFooterSymbol {
  order: 2 !important;
  font-size: 48px !important;
  color: var(--step-footer-icon-color) !important;
  border: solid 1px #f5fafe;
  background: #ffffff8f;
  border-radius: 50px;
  padding: 12px;
  box-shadow: 0px 1px 5px #aaa;
}

.pageFooterItem {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 8px !important;
  margin-bottom: 8px !important;
}

.pageFooterItem > span {
  color: #2466b0;
}

@media (max-width: 767px) {
  .pageFooterSymbol {
    display: none !important;
  }
}
