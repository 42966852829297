@import "src/scss/_vars.scss";

.submit {
  flex-direction: row-reverse;
}

.wrapSave {
  display: inline-block;
}

.row {
  margin-top: 4rem;
  & > div:last-child {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    & div {
      flex: 1 1;

      & button {
        width: 100%;
        border-radius: 4px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .row {
    margin-top: 2.812rem;
  }
}
