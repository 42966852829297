.header {
  background-color: var(--review-step-header-background-color);
  padding: 0.5rem 0.5rem;
  margin: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  h4 {
    color: white;
    width: 12rem;
    display: inline-block;
    font-size: 1rem;
    line-height: 1rem;
    margin: 0;
  }

  a {
    color: white;
    text-decoration: underline;
  }
}

:global(.onlyForBoats) .header{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 4px 16px !important;
}

.validationError {
  color: red;
  margin-top: -1em;
}

.values {
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  margin-bottom: 1rem;
  padding: 0.4rem 0.3rem;
}

:global(.onlyForBoats) .values{
  text-align: left !important;

  p {
    max-width: 66.66666667% !important;
    margin: 12px auto !important;
  }
}

div[data-testid="sellerCollateralURL"] p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
