.table {
  width: 100%;
  overflow-x: auto;

  th,
  td {
    padding: 0.5rem;
  }

  @media (max-width: 767px) {
    thead {
      display: none;
    }

    tr {
      display: block;
      margin-bottom: 1rem;
      border: 1px solid #ddd;
    }

    td {
      display: block;
      text-align: right;
      border-bottom: 1px solid #ddd;
      min-height: 2.5rem;

      &:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
      }

      &:last-child {
        border-bottom: none;
      }

      &:empty {
        &:before {
          content: attr(data-label) " : N/A";
          float: none;
          text-align: left;
          font-weight: normal;
          font-style: italic;
          color: #999;
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 0.9rem;
    
    th, td {
      padding: 0.3rem;
    }
  }

  @media (min-width: 1024px) {
    min-width: 900px;
  }
}

