@import "src/scss/_vars.scss";

.mainLayout {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;

  form {
    margin-top: 2rem;
  }
}

.onlyForBoat{
    :global {
    .ant-col-xl-16,
    .ant-col-lg-16,
    .ant-col-md-16,
    .ant-col-sm-24,
    .ant-col-xs-24 {
        flex: 100% !important;
        max-width: 100% !important;
      }
    
    h1 ~ p{
      max-width: 66.66666667% !important;
      margin: 12px auto !important;
      text-align: center !important;
    }

    .ant-btn-primary {
      border-radius: 50px !important;
    }
    .ant-btn-primary:hover {
      border-radius: 50px !important;
    }
    .ant-btn {
      border-radius: 50px !important;
    }
    .ant-space .ant-space-horizontal .ant-space-align-center {
      gap: 30px !important;
    }

    @media only screen and (max-width: 640px) {
      h1, h2 {
        text-align: center !important;
      }
    }

  }
}

.newFunnelV1{
  :global{

    input {
      width: 100%;
      display: flex;
      height: 60px !important;
      padding: 8px 24px;
      justify-content: left;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px !important;
      border: 1px solid #E0E0E0;
      background: #FFF !important;
      color: #757575;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      &:focus{
        border : 2px solid #015FCC !important;
        box-shadow: none;
      }
    }

    /* For WebKit-based browsers */
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
      -webkit-text-fill-color: #757575;
    }

    input:-moz-autofill {
      box-shadow: 0 0 0px 1000px #ffffff inset;
      color: #757575; /* Change the text color to black */
    }

    // Styles for buttons

    .ant-form-horizontal{
      margin-top: 7px;
    }

    .ant-form-item-control-input-content #back, 
    .ant-form-item-control-input-content #save{
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      color: #757575;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      height: 2.3rem;
      text-transform: lowercase;

      span {
        text-decoration: underline !important;
      }
    }

    // Styles for header
    .form-container > div:first-child {
      width: 100%;
      color: #2566B0;
      font-family: Roboto;
      font-size: 14px;
      line-height: 12px;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 12px;
      font-weight: 500 !important;
    }

    .form-container h1 {
      text-align: left;
      width: 100%;
      color: #404040;
      font-family: Roboto;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 12px;
    }

    .btn-user {
      color: #2566b0 !important;
    }
    .btn-user .anticon-user,
    .btn-user .anticon-down{ 
      color: #2566b0 !important;
    }

    .btn-user .anticon-user{ 
      color: #2566b0 !important;
    }

    div[class^="ant-col loan_title"] {
      color: #2566b0 !important;
    }

    .form-container h1 ~ p,
    .form-container div p:first-child {
      text-align: left !important;
      width: 100%;
      color: #757575;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      margin: 0px 0px 12px 0px !important;
    }

    .ant-form-item-control-input-content > div[class^="PlaceholderLabel_placeLabel"]{
      display: flex;
      flex-direction: column-reverse;
      padding-top: 12px;

      label {
        font-weight: 700 !important;
        font-size: 0.875rem !important; 
        line-height: 1.05rem !important;
        position: unset !important;
        top: 10px !important;
        text-transform: unset !important;

        span {
          color: #db3f3f !important;
          margin-left: 4px !important;
        }

      }
    }
    
    .ant-form-item-control-input-content label span span span{
      color: #db3f3f !important;
      margin-left: 4px !important;
    }

    // Styles for select 
    .ant-select-focused{
      border : 2px solid #015FCC !important;
      box-shadow: none;
      border-radius: 10px !important;

      .ant-select-clear{
        right: 0.375rem !important;
      }

    }

    .ant-select-selector {
      border-radius: 8px !important;
      height: 60px !important;
      background: #FFF !important;

    }

    .ant-select-clear{
      top: 1.75rem !important;
    }

    .ant-form-item-children-icon{
      margin-top: 5px !important;
    }

    .ant-select-selection-search-input{
      height: 55px !important;
      border-radius: 8px !important;
      &:focus {
        border : none !important;
      }
    }

    .ant-select-selection-search {
      left: 0;
    }

    .ant-select-selection-item{
      padding-left: 8px;
      color: #757575;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    // Styles for input-number
    .ant-input-number{
      border-radius: 8px;
      border: none;
    }

    .ant-input-number-input{
      padding-left: 8px;
    }

    // Styles for buttons

    .ant-radio-button-wrapper .ant-radio-button {
      border-radius: 0;
    }

    .ant-radio-button-wrapper:first-child,
    .ant-radio-button-wrapper:first-child .ant-radio-button{
      border-radius: 8px 0 0 8px;
    }

    .ant-radio-button-wrapper:last-child,
    .ant-radio-button-wrapper:last-child .ant-radio-button {
      border-radius: 0 8px 8px 0;
    }

    //Styles for radio buttons
    .ant-radio-button-wrapper {
      background-color: #FFF !important;
      color: #404040;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    & .ant-radio-button-wrapper-checked {
      border: none;
      border-radius: none;
      span{
        color: #FFF;
      }
    }

    & .ant-radio-button-checked {
      background: #2566B0 !important;

    }

    // Style for review Tables
    .review-step-table{
      div:first-child{
        border-radius: 8px 8px 0 0 !important;
      }
      div:last-child{
        border-radius: 0 0 8px 8px !important;
      }
    }


    // Styles for header 
    header > div{
      background-color: #fff !important;
      border: none;
    }

    // Styles for footer
    footer {
      background: #2566B01A !important;
      color: #2566B0 !important;

      a {
        color: #2566B0;
    
        &:hover {
          text-decoration: underline;
        }
      }

      svg {
        fill: #2566B0;
      }

      #footer-logo{
        width: 218px !important;
      }

    }

    // Styles for prop box
    div[class^="StepFooter_pageFooterContainer"]{
      box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);
      border: none !important;
      border-radius: 8px !important;
      background-color: white !important;
    }
    

  }
}

.header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0;
  height: auto;
}

.headerRow {
  background-color: var(--header-background-color);
  border-bottom: 1px solid #E8E8E9;
  padding: 0 5rem;
  @include break("tablet") {
    padding: 0 1rem;
  }
}

.title {
  color: var(--login-button-text-color);
  font-size: 1.3rem;
  text-align: center;
  display: block;
}

.secured {
  font-size: 0.8rem;
  font-weight: bold;
  position: absolute;
  right: 1rem;

  svg {
    padding-top: 0;
    margin-right: 0.4rem;
  }

  @include break("tablet") {
    display: none;
  }
}

.progress {
  flex: 1;
  width: 100vw;
  line-height: 0;

  :global {
    .ant-progress-outer {
      padding: 0;
      line-height: 0;
    }
  }
}

.content {
  margin-top: 2rem;
  margin-bottom: 6rem;
  min-height: 200px;
  flex: 1 0 auto;

  h1 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    line-height: 1.92rem;
  }
  h2 {
    margin-top: -12px;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    line-height: 1.92rem;
  }
}

.applicationId {
  font-size: 0.8rem;
  color: #606E86;
  width: 100%;
  margin: 1rem 0;
  justify-content: center;
}

:global {
  .small {
    font-size: 0.7rem;
  }

  .disclaimer {
    font-size: 0.8rem;
    text-align: justify;

    .header {
      font-weight: 700;
      
      span {
        display: table-cell;
        padding: 0 1em;
      }
    }

    .link {
      color: #0645AD;
      text-decoration: underline;
    }
  }

  .fade-enter main {
    opacity: 0;
  }

  .fade-enter.fade-enter-active main {
    opacity: 1;
    transition: opacity 250ms;
    transition-timing-function: ease-in;
    z-index: 1;
  }

  .fade-enter-done main {
    opacity: 1;
  }

  .fade-exit {
    display: none;
    opacity: 0;
  }

  .form-container {
    max-width: $max_width;
    margin: auto;
    padding: 0 1rem;


    & > form > div:last-child {
      margin-bottom: -16px !important;
    }
  }

  .assetsLayout {
    & > .form-container {
      max-width: $max_width_assets;
    }
  }

  .submitLayout {
    & > .form-container {
      max-width: $max_width_submit;
    }
  }

  .button-form {
    .ant-radio-button-wrapper {
      font-size: 2.5rem;
      line-height: 1.8em;
      height: 2rem;
    }

    text-align: center;
  }

  label.manual {
    > span {
      font-weight: 700;
      font-size: .875rem;
      line-height: 1.05rem;
      padding-top: .5rem;
      padding-bottom: .8rem;
    }

    > span.two-line {
      display: block;
    }

    &.placeholder {
      font-size: 0.7rem !important; // freaking ant
      display: block;
    }
  }

  label.manual.two-line {
    > span {
      display: block;
    }
  }

  .ant-form-item {
    min-height: 3rem;
    margin-bottom: 1rem;

    label {
      font-size: .875rem;
    }

    &.manual-label {
      display: flex;
      flex-direction: row;
      align-content: center;
      padding-bottom: .875rem;
    }
  }

  .ant-radio-group .ant-radio-button-wrapper {
    margin-right: 0;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    display: none;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #d9d9d9;
  }

  .ant-input-number {
    width: 100%;
    border-radius: 4px;
  }

  .required-mark {
    color: #db3f3f !important;
  }

  .ant-btn {
    padding: 0.2em 1.7em;
    line-height: 1em;
    height: 2.3em;
  }

  .ant-modal-close-x {
    font-size: inherit;
  }

  .ant-modal-title {
    font-size: 1.5rem;
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 1.5rem;
  }

  .ant-radio-button {
    border-radius: 4px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #384a68;
    border-color: #384a68;
    border-width: 2px;
    color: #333 !important;
    font-weight: 700;


    &:hover {
      background: #526075;
      border-color: #526075;
      color: #fff;
    }
  }

  .firebase-emulator-warning {
    display: none;
  }

  .ant-input,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .print-only {
    display: none;
  }

  .ant-form-item-explain,
  .ant-form-item-explain-error {
    color: rgb(255, 77, 79);
  }

  .privacyPolicyLink {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    color: #808B9E;
  }
}

.sectionHeads {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  max-width: $max_width;
  padding: 0 0.5em;
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin: 1.5rem auto 0;
}

.sectionHead {
  flex: 1;
  display: inline-block;
  line-height: 1em;
  text-align: center;
  font-size: 0.9rem;

  a {
    text-decoration: underline;
  }
}

.sectionHeadBar {
  background-color: var(--section-bar-background-color);
  height: 5px;
  width: 100%;
  margin-top: 0.5em;
}

.sectionHeadBarCompleted {
  background-color: green;
}

.sectionThirdPartyDisclaimer {
  margin-bottom: 1rem;
  a {
    // background-color: blue;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
      color: #23527c;
    }
  }
}

.innerRow {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 2.34rem;

}

.column33 {
  width: 33%;
}

.column45 {
  width: 45%;
}

.column60 {
  width: 60%;
}

.detailsLiveAboardOptions {
  & .ant-radio-group > label.ant-radio-button-wrapper {
    width: 25%;
  }
}

@media only screen and (max-width: 640px) {
  .mainLayout {
    form {
      margin-top: 1.5rem;
    }
  }

  .content {
    margin-bottom: 20px;
    margin-top: 24px;

    h1, H2 {
      font-size: 1.333rem;
      text-align: left;
      margin-bottom: 24px;
    }
  }

  .innerRow {
    flex-flow: row wrap;
    row-gap: 0;
  }

  .column33 {
    width: 50%;
  }

  .column45 {
    width: 75%;
  }

  .column60 {
    width: 100%;
  }
}