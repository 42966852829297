@import "src/scss/_vars.scss";

.pqSplashContent {
  width: 100%;
}

.pqSplashContentContainer {
  display: flex;
  flex-direction: column;
}

.pqSplashHeader {
  background-color: #fff;
  padding: 0;
  height: auto;
}

.pqSplashHeaderRow {
  padding-left: 16px;
}

.pqSplashFooterCard {
  align-items: center;
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.20); 
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  gap: 10px;
  min-height: 172px;
  min-width: 266px;
  padding: 16px;
  text-align: center;
}

.pqSplashFooterCardTitle {
  color:#404040;
  margin: auto;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.pqSplashCenterContent {
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 120px;
  padding-right: 120px;
}

.pqSplashGetPreQualHeaderText { 
  color: var(--Neutral-neutral-90, #404040);
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 36px;
  line-height: 32px;
}

.pqSplashBottomContent {
  background: var(--Neutral-neutral-30, #EDEDED);
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 120px;
  padding-right: 120px;

  h1 {
    color: var(--Neutral-neutral-90, #404040);
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  h3 {
    color: var(--Neutral-neutral-90, #404040);
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.pqTrustPilotLogo{
  margin-left: 12px;
}

.pqSplashReviewFooterCard {
  display: flex;
  min-width: 266px;
  padding: 24px;
  min-height: 224px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.20);
  
  p {
    padding: 0;
    margin: 0 !important;
    max-width: unset !important;
  }

  h3 {
    color: var(--Neutral-neutral-90, #404040);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; 
  }

  p.pqSplashReviewFooterCardAuthor {
    color: var(--Neutral-neutral-60, #9E9E9E);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; 
  }

  p.pqSplashReviewFooterCardContent {
    color: var(--Neutral-neutral-90, #404040);
    text-align: center;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
}

.pqSplashHero {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  min-width: 100%;
}

.pqSplashHeroContentText{
  position: relative;
}

.pqSplashHeroImage{
  position: absolute;
  opacity: .5;
  left: 0;
  width: 100%;
  object-fit: cover;
  height: 400px;
}

.pqSplashHeroImageMobile {
  display: none;
}

.pqSplashHeroText {
  text-align: center;
  color: var(--Neutral-neutral-90, #404040);
  font-style: normal;
  font-family: Roboto, sans-serif;
}

h1.pqSplashHeroText {
  font-size: 56px;
  font-weight: 700;
  line-height: normal;
  margin-top: 64px;
}

h2.pqSplashHeroText {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 0;
  margin-top: 24px;
}

.pqSplashHeroCTA {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pqSplashGetStartedButton {
  font-family: 'Roboto', sans-serif;
  align-items: center;
  background: #2566B0;
  border-radius: 100px;
  color: #fff;
  display: flex;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  letter-spacing: 0.4px;
  line-height: 40px;
  width: 375px;

  &:hover {
    color: #fff
  }
}

.pqSplashCardContainer { 
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  flex: 1 0 0;
  flex-wrap: wrap;
  padding-top: 24px;

  :global{
    & .card {
      display: flex;
      height: 120px;
      min-width: 266px;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex: 1 0 0;
      border-radius: 8px;
      background: #FFF;
      box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.20);
    }

    & .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      & .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
      }

      & .title {
        color:#404040;
        font-family: Roboto, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; 
      }

    }

    & .text{
      color: #404040;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }
}

.pqSplashFooter {
  background-color: #104F79;
  height: 86px;
  width: 100%;
}

@media only screen and (min-width: 501px) and (max-width: 532px) {
  .pqSplashHeroImage{
    height: 456px;
  }

  .pqSplashHero{
    height: 456px;
  }
}

@media only screen and (max-width: 500px) {
  .pqSplashHeaderRow {
    padding-left: 0;
  }

  .pqSplashHeader {
      margin-left: 16px;
  }

  .pqSplashReviewFooterCard {
    height: 332px;
  }

  .pqSplashHeroImageMobile {
    display: block;
    position: absolute;
    opacity: .3;
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-position: 60% 51%;
  }

  .pqSplashCenterContent, .pqSplashHeroText, .pqSplashBottomContent {
    padding-left: 24px;
    padding-right: 24px;
  }

  .pqSplashFooterCardContentContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .pqSplashFooterCard {
    align-items:flex-start;
    text-align: left;
  }

  .pqSplashFooterCardTitle {
    margin-left: 12px;
  }

  .pqSplashBottomContent {
    h1 {
      color: var(--Neutral-neutral-90, #404040);
      text-align: center;
      font-family: Roboto, sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; 
    }

    h3.pqSplashBottomContentExpl {
      color: var(--Neutral-neutral-90, #404040);
      text-align: center;
      font-family: Roboto, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; 
    }
  }

  .pqSplashGetStartedButton {
    width: 343px;
  }

  .pqSplashGetPreQualHeaderText {
    color: var(--Neutral-neutral-90, #404040);
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; 
  }

  h1.pqSplashHeroText {
    font-size: 52px;
    margin-top: 35px;
  }
}

@media only screen and (min-width: 768px) {
  .pqSplashCenterContent, .pqSplashBottomContent {
    padding-left: 48px;
    padding-right: 48px;
  }

  .pqSplashReviewFooterCard {
    height: 312px;
  }
}

@media only screen and (min-width: 1200px) {
  .pqSplashContent {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
  .pqSplashHeaderContainer {
    display: flex;
    align-content: center;
    justify-content: center;
  }
  
  .pqSplashHeader {
    margin-left: 0;
    width: 1200px;
  }

  .pqSplashCenterContent, .pqSplashBottomContent {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .pqSplashBottomContent {
    padding-left: 0;
    padding-right: 0;
  }
  
  .pqSplashCardContainer {
    max-width: 1164px;
    display: flex;
    flex-direction: row;
  }

  .pqSplashReviewFooterCard {
    align-items: center;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    height: 312px;
    flex-direction: column;
    flex: 1 0 0;
    gap: 10px;
    min-width: 266px;
    padding: 24px;
    text-align: center;

    .pqSplashReviewFooterCardContentContainer {
      padding-left: 120px;
      padding-right: 120px;
    }
  }  
}
