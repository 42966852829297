@import "src/scss/_vars.scss";

.mainLayout {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;

  form {
    margin-top: 7px;
  }
}

.header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0;
  height: auto;
  @include break('tablet') {
    align-items: center;
  }
}

.headerRow {
  padding: 0 5rem;
  @include break("tablet") {
    padding: 0 1rem;
  }
}

.title {
  color: var(--login-button-text-color);
  font-size: 1.3rem;
  text-align: center;
  display: block;
}

.secured {
  font-size: 0.8rem;
  font-weight: bold;
  position: absolute;
  right: 1rem;

  svg {
    padding-top: 0;
    margin-right: 0.4rem;
  }

  @include break("tablet") {
    display: none;
  }
}

.progress {
  flex: 1;
  width: 100vw;
  line-height: 0;

  :global {
    .ant-progress-outer {
      padding: 0;
      line-height: 0;
    }
  }
}

.content {
  margin-top: 2rem;
  margin-bottom: 6rem;
  min-height: 200px;
  flex: 1 0 auto;

  h1 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    line-height: 1.92rem;
  }
  h2 {
    margin-top: -12px;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    line-height: 1.92rem;
  }

  :global {
    & .form-container{
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

}

.applicationId {
  font-size: 0.8rem;
  color: #606E86;
  width: 100%;
  margin: 1rem 0;
  justify-content: center;
}

:global {
  .small {
    font-size: 0.7rem;
  }

  .disclaimer {
    font-size: 0.8rem;
    text-align: justify;

    .header {
      font-weight: 700;
      
      span {
        display: table-cell;
        padding: 0 1em;
      }
    }

    .link {
      color: #0645AD;
      text-decoration: underline;
    }
  }

  .fade-enter main {
    opacity: 0;
  }

  .fade-enter.fade-enter-active main {
    opacity: 1;
    transition: opacity 250ms;
    transition-timing-function: ease-in;
    z-index: 1;
  }

  .fade-enter-done main {
    opacity: 1;
  }

  .fade-exit {
    display: none;
    opacity: 0;
  }

  .form-container {
    max-width: $max_width;
    margin: auto;
    padding: 0 1rem;


    & > form > div:last-child {
      margin-bottom: 0 !important;
    }
  }

  .assetsLayout {
    & > .form-container {
      max-width: $max_width_assets;
    }
  }

  .submitLayout {
    & > .form-container {
      max-width: $max_width_submit;
    }
  }

  .button-form {
    .ant-radio-button-wrapper {
      font-size: 2.5rem;
      line-height: 1.8em;
      height: 2rem;
    }

    text-align: center;
  }

  label.manual {
    > span {
      font-weight: 700;
      font-size: .875rem;
      line-height: 1.05rem;
      padding-top: .5rem;
      padding-bottom: .8rem;
    }

    > span.two-line {
      display: block;
    }

    &.placeholder {
      font-size: 0.7rem !important; // freaking ant
      display: block;
    }
  }

  label.manual.two-line {
    > span {
      display: block;
    }
  }

  .ant-form-item {
    min-height: 3rem;
    margin-bottom: 1rem;

    label {
      font-size: .875rem;
    }

    &.manual-label {
      display: flex;
      flex-direction: row;
      align-content: center;
      padding-bottom: .875rem;
    }
  }

  .ant-radio-group .ant-radio-button-wrapper {
    margin-right: 0;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    display: none;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #d9d9d9;
  }

  .ant-input-number {
    width: 100%;
    border-radius: 4px;
  }

  .required-mark {
    color: #db3f3f !important;
  }

  .ant-btn {
    padding: 0.2em 1.7em;
    line-height: 1em;
    height: 2.3em;
  }

  .ant-modal-close-x {
    font-size: inherit;
  }

  .ant-modal-title {
    font-size: 1.5rem;
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 1.5rem;
  }

  .ant-radio-button {
    border-radius: 4px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #384a68;
    border-color: #384a68;
    border-width: 2px;
    color: #333 !important;
    font-weight: 700;


    &:hover {
      background: #526075;
      border-color: #526075;
      color: #fff;
    }
  }

  .firebase-emulator-warning {
    display: none;
  }

  .ant-input,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .print-only {
    display: none;
  }

  .ant-form-item-explain,
  .ant-form-item-explain-error {
    color: rgb(255, 77, 79);
  }

  .privacyPolicyLink {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    color: #808B9E;
  }
}

.sectionHeads {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  max-width: $max_width;
  padding: 0 0.5em;
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin: 1.5rem auto 0;
}

.sectionHead {
  flex: 1;
  display: inline-block;
  line-height: 1em;
  text-align: center;
  font-size: 0.9rem;

  a {
    text-decoration: underline;
  }
}

.sectionHeadBar {
  background-color: var(--section-bar-background-color);
  height: 5px;
  width: 100%;
  margin-top: 0.5em;
}

.sectionHeadBarCompleted {
  background-color: green;
}

.sectionThirdPartyDisclaimer {
  margin-bottom: 1rem;
  a {
    // background-color: blue;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
      color: #23527c;
    }
  }
}

.innerRow {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 2.34rem;

}

.column33 {
  width: 33%;
}

.column45 {
  width: 45%;
}

.column60 {
  width: 60%;
}

.detailsLiveAboardOptions {
  & .ant-radio-group > label.ant-radio-button-wrapper {
    width: 25%;
  }
}

.subheader {
  background: #2566B01A;
}

.subheaderIcon {
  font-size: 15px;
  background: #fff;
  padding: 5px;
  border-radius: 50px;
}

.subheaderCol {
  text-align: center;
  width: 14%;
  @include break('laptop-lg') {
    width: 20%;
  }
}

@media (max-width: 1000px) {
  .subheaderCol:nth-child(1),
  .subheaderCol:nth-child(2) {
    display: none;
  }
  .subheaderCol:nth-child(3) {
    width: 100%;
  }
  .subheaderRow {
    justify-content: center;
  }
}

.subheaderRow {
  text-justify: space-around;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: #2566B0;
  justify-content: center;
}

@media only screen and (max-width: 640px) {
  .mainLayout {
    form {
      margin-top: 1.5rem;
    }
  }

  .content {
    margin-bottom: 20px;
    margin-top: 24px;

    h1, H2 {
      font-size: 1.333rem;
      text-align: left;
      margin-bottom: 24px;
    }
  }

  .innerRow {
    flex-flow: row wrap;
    row-gap: 0;
  }

  .column33 {
    width: 50%;
  }

  .column45 {
    width: 75%;
  }

  .column60 {
    width: 100%;
  }
}