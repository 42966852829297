$color_dark_tf: #3a4c68;
$max_width: 620px;
$max_width_assets: 710px;
$max_width_submit: 750px;
$max_width_progress_bar: 820px;


@mixin break($breakpoint) {
  @if $breakpoint == laptop-lg {
    @media (max-width: 1350px) {
      @content;
    }
  }
  @if $breakpoint == laptop {
    @media (max-width: 1024px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (max-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == mobile {
    @media (max-width: 425px) {
      @content;
    }
  }
}
