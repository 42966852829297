.radioButtonsGroup {
  width: 100%;
  :global{
    & label{
        width: 100%;
        display: flex;
        height: 60px;
        padding: 8px 24px;
        justify-content: left;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px !important;
        border: 1px solid #E0E0E0;
        background: #FFF !important;
        color: #404040;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    & .ant-radio-button-wrapper-checked {
      border: 1px solid #E0E0E0 !important;
      span{
        border-radius: 8px !important;
        color: #FFF;
      }
    }

    & .ant-radio-button-checked {
      background: #2566B0 !important;

    }
  }
}