.applicationTypeRadioGroup {
  display: flex;
  flex-flow: row nowrap;
  width: 15.5rem;
  & label {
    width: 50%;
    & span:last-child {
      margin: auto !important;
    }
  }
}

@media screen and (max-width: 640px) {
  .rowVerticalSpacing {
    margin-bottom: 0 !important;
  }
}


