.footerContainer{
  display: flex;
  padding: 16px 22px;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  background: #FFF;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.20);

  :global{
    & .content{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 13px;
      align-self: stretch;
    }

    & .text-container{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
    }

    & .title{
      color: #404040;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
    }

    & .item{
      display: flex;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      color: var(--Neutral-neutral-90, #404040);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }

    & .icon{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 46px;
    }

    & .check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
    }

    a{
      color: #404040;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      text-decoration-line: underline;
    }
  }
}