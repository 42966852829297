.addButton {
  margin-left: 2rem;
}

.tableActionRow {
  margin: 0.86rem 0;
}

.borrowerName {
  width: 100%;
  text-align: center;
}

.totalLabel {
  text-align: right;
}

:global(.onlyForBoats) .totalLabel {
  text-align: left !important;
}

.totalAmount {
  text-align: right;
}

.totalEdit {
  text-align: left;
  margin-left: 1rem;
}

.statementGrid {
  margin-bottom: 2rem;
}


@media screen and (max-width: 640px) {
  .totalLabel {
    text-align: left;
  }

  .totalEdit:last-child {
    text-align: center;
  }

  .hideForMobile {
    display: none;
  }

  .noWrapInMobile, .ant-row {
    margin-bottom: -6px !important;
    flex-flow: row nowrap !important;
  }
}
