@import "src/scss/_vars.scss";

.navigationRow {
  display: flex;
  justify-content: center;
  padding: 24px 0;
}

.navigationCol {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include break("tablet") {
    width: 100%;
  }
}

.backLink {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #757575;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  height: 2.3rem;
  text-decoration: underline;

  &:hover{
    background: unset !important;
    border-color: unset !important;
    color: #757575 !important;
  }

}

.backLink {
  & > span {
    text-decoration: underline;
  }
}

.backLink:hover {
  color: #757575;
}

.forwardButton {

    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 50px;
    background-color: rgba(37, 102, 176, 0.5) !important; /* 50% transparent #2566B0 */
    border-width: 0;
    &:hover {
      background-color: #2566B0;
    }
    & div{
      color: #FFF !important;
    }
    :disabled{
      background-color: rgba(37, 102, 176, 0.5) !important; /* 50% transparent #2566B0 */
    }
}

:global{
  .prequalButtonEnabled{
      background-color: #2566B0 !important;
  }
}