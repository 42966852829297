.pqDisclaimerText {
  color: var(--Neutral-neutral-70, #757575);
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  white-space: pre-line;
  text-align: left !important;
  margin: 0 0 12px 0 !important;
  min-width: 100% !important;
}
