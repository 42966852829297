.addOnServicesCheckboxGroup {
  & label {
    margin-left: 0px !important;
    margin-right: 8px !important;
  }
  & span {
    font-family: "Open Sans";
    color: #2C272D;
  }
}

:global(.onlyForBoats) .loanAmount{
  text-align: center !important;
}

.loanAmount{
  text-align: left;
}

.addOnServicesLabel {
  display: block;
}

.addOnServicesInfoicon {
  margin-left: 8px;
  & span {
    color: #93A7CB;
    font-family: "Lato";
    font-size: 0.875rem;
  }
}

.addOnServices {
  margin-top: 40px;
  margin-bottom: 100px;
  width: 120%;
  display: block;
}

.addOnServicesDisclaimer {
  margin-top: 16px;
  font-size: 0.75rem;
  & span {
    font-weight: bold;
  }
}

@media screen and (max-width: 640px) {
  .addOnServices {
    width: 100%;
  }
}
