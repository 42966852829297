@import "src/scss/_vars.scss";

.footer {
  border-top: 1px solid #c8cfd4;
  background: #2566B01A;
  color: #2566B0;
  flex-shrink: 0;

  a {
    color: #2566B0;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footerWrap {
  display: flex;
  flex-direction: column;
  max-width: $max_width;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  @include break("tablet") {
    padding: 0;
  }
}

.columnsWrap {
  display: flex;
  flex-direction: row;
  @include break('tablet') {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.logoWrap {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.left {
  a {
    display: block;
  }
}

.right {
  @include break("tablet") {
    margin-bottom: 1.5rem;
    width: 228px;
  }
}

.secured {
  display: block;
  align-content: center;
  svg {
    opacity: 0.6;
    padding-top: 0;
    margin-right: 0.4rem;
  }
}

.copy {
  margin-right: 2rem;
  opacity: 0.6;
}

.space {
  flex-grow: 1;
}

.needHelpTop {
  margin-bottom: 1rem;
}

.needHelp {
  font-size: 1.2rem;
  font-weight: bold;
}

.phoneContainer {
  display: flex;
}

.phones {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.phoneLeft {
  margin-top: 0.2rem;
  margin-right: 0.5rem;
}