.sectionHeader{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  font-style: normal !important;
  font-weight: 500 !important;

  span {
    color: #2566B0;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  :global {
    .title{
      width: 100%;
      color:  #2566B0;
      font-family: Roboto;
      font-size: 14px;
      line-height: 12px;
      text-align: left;
      text-transform: uppercase;
    }
    
    .subtitle {
      width: 100%;
      color: #404040;
      font-family: Roboto;
      font-size: 20px;
      line-height: 24px;
    }
    
    .content {
      width: 100%;
      color: #757575;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px; /* 100% */
    }
  }
  
}