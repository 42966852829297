@import "src/scss/_vars.scss";


.aboutYouContainer{
  display: flex;
  flex-flow: row wrap;
  margin-left: -4px;
  margin-right: -4px; 
  row-gap: 0px;

}

.aboutYouTextItem {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 4px;
    padding-right: 4px;

    @include break("tablet") {
      flex: 100% !important;
      max-width: 100% !important;    
    }
}