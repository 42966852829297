::selection {
  background: var(--selection-background-color);
  color: #fff;
}

:global {
  .ant-btn {
    background: var(--default-button-background-color);
    border-color: var(--default-button-border-color);
    color: var(--default-button-text-color);
  }

  .ant-btn:focus, .ant-btn:hover {
    background: var(--default-button-hover-background-color);
    border-color: var(--default-button-hover-border-color);
    color: var(--default-button-hover-text-color);
  }

  .ant-btn-primary {
    background: var(--primary-button-background-color);
    border-color: var(--primary-button-border-color);
    color: var(--primary-button-text-color);
  }

  .ant-btn-primary:focus, .ant-btn-primary:hover {
    background: var(--primary-button-hover-background-color);
    border-color: var(--primary-button-hover-border-color);
    color: var(--primary-button-hover-text-color);
  }

  .btn-login, .btn-login:focus {
    background: var(--login-button-background-color);
    border-color: var(--login-button-border-color);
    color: var(--login-button-text-color);
  }

  .btn-login:hover {
    background: var(--login-button-hover-background-color);
    border-color: var(--login-button-hover-border-color);
    color: var(--login-button-hover-text-color);
  }

  .btn-user, .btn-user:focus {
    color: var(--login-button-text-color);
  }

  .btn-user:hover {
    color: var(--login-button-text-color);
  }

  .ant-radio-button {
    background: var(--default-button-background-color) !important;
    border-color: var(--default-button-border-color) !important;
    height: 2.625rem;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .ant-radio-button-checked {
    background: var(--radiobutton-selected-background-color) !important;
    border-color: var(--radiobutton-selected-border-color) !important;
  }

  .ant-radio-button-wrapper {
    background-color: #F5FAFF !important;
  }
}