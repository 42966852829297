@import "src/scss/_vars.scss";

.progressBar {
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  background-color: #F9F9F9;
  border-bottom: solid 2px #E8E8E9;
  > div {
    display: flex;
    flex-grow: 1;
    max-width: $max_width_progress_bar;
  }
}

.progressBarImageContainer{
  margin: 25px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressBarImage{
  width: 100%;
  height: 40px;
}

.progressBarStep {
  display: flex;
  flex-direction: column;
  margin: 0;
  flex-grow: 1;
}

.progressBarStep__progressContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.progressBarStep__bar {
  height: 3px;
  flex-grow: 1;
  background-color: var(--section-bar-background-color);
}

.progressBarStep__bar--hidden {
  opacity: 0; // We need this instead of display: none to keep the size of the bars
}

.progressBarStep__bar--active {
  background-color: var(--progress-bar-color);
}

.progressBarStep__center {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid var(--section-bar-background-color) 3px;
  border-radius: 50%;
  height: 1.875rem;
  aspect-ratio: 1;
}

.progressBarStep__center--current {
  border-color: var(--progress-bar-color);
}

.progressBarStep__center--done {
  border-color: var(--progress-bar-color);
  background-color: var(--progress-bar-color);
}

.progressBarStep__check {
  background-color: var(--progress-bar-color);
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.progressBarStep__dot {
  width: 100%;
  height: 100%;
  border: solid 4px white;
  border-radius: 50%;
  background-color: var(--progress-bar-color);
}

.progressBarStep__labelContainer {
  display: flex;
  justify-content: center;
}

.progressBar__label {
  user-select: none;
  margin-top: 0.5rem;
  line-height: 1.5rem;
  font-size: .875rem;
}

.progressBarStep__label--current {
  font-weight: bold;
}